import { useEffect, useState } from "react"
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import ptLocale from '@fullcalendar/core/locales/pt-br'
import interactionPlugin from "@fullcalendar/interaction"
import api from "../components/api"
import moment from 'moment'
import { useNavigate } from "react-router"

const AgendaPregoes = () => {

    var anoAtual = moment().format('YYYY')
    var mesAtual = moment().format('MM')

    const [eventos, setEventos] = useState([])
    const [mes, setMes] = useState(mesAtual)
    const [ano, setAno] = useState(anoAtual)

    //const [currentMonth, setCurrentMonth] = useState(new Date().toISOString().slice(0, 7)); // Estado para armazenar o mês atual

    const navigate = useNavigate()


    const carrega = () => {

        //console.log(mes, ano)

        api.get(`pregoesMesWeb/${ano}/${mes}`).then((result) => {

            //console.log(result.data)

            setEventos(result.data)

        }).catch((err) => {

            console.log(err.response)

        })


    }
    const handleDateChange = (info) => {
        const startDate = new Date(info.startStr);
        const endDate = new Date(info.endStr);
      
        // Calcular o meio do intervalo visível
        const midDate = new Date((startDate.getTime() + endDate.getTime()) / 2);
      
        // Obter o mês e o ano do meio do intervalo
        const focusedMonth = midDate.getMonth() + 1; // Mês (ajustado de 0-11 para 1-12)
        const focusedYear = midDate.getFullYear();   // Ano

        setMes(focusedMonth)
        setAno(focusedYear)
      
        //console.log(`Mês focalizado: ${focusedMonth}, Ano focalizado: ${focusedYear}`);
      };

      
    useEffect(() => {

        

        if (mes && ano) {

            carrega()

        }      


    }, [mes, ano])

    
    return (

        <div>


            <div id="contentwrapper">
                <div class="main_content">
                    <div id="jCrumbs" class="breadCrumb module">
                        <ul>
                            <li>
                                <a href="#"><i class="glyphicon glyphicon-home"></i></a>
                            </li>
                            <li>
                                Agenda Pregões
                            </li>
                            
                        </ul>
                    </div>	
                    <div class="row">
                        <div class="col-sm-12 col-md-12">


                            <FullCalendar 
                                locale={ptLocale}
                                plugins={[dayGridPlugin, interactionPlugin]}
                                initialView="dayGridMonth"
                                events={eventos}
                                datesSet={handleDateChange}
                                displayEventTime={false}
                                contentHeight="auto"
                                color="#FFF"
                                eventClick={
                                    function (arg) {

                                        //alert(arg.event.title)
                                        //alert(arg.event.id)
                                        //console.log(arg.event.id)
                                        navigate(`/Item/${arg.event.id}`)
                                        //history.push(`/Evento/${arg.event.id}`)

                                    }
                                }

                            />




                        </div>
                    </div>                
                </div>
            </div>

        



        </div>

    )

}


export default AgendaPregoes