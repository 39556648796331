import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { ProtectedRoute } from "./components/protected.route";

import Layout from './components/Layout'

import Login from './pages/Login'
import Home from './pages/Home'
import Catalogo from './pages/Catalogo'
import Planejamento from './pages/Planejamento'
import Processos from './pages/Processos'
import Empenhos from './pages/Empenhos'
import Item from './pages/Item'
import Entrega from './pages/Entrega'
import Importacao from './pages/Importacao'
import Indicadores from './pages/Indicadores'
import Usuarios from './pages/Usuarios'
import Usuario from './pages/Usuario'
import Entregas from './pages/Entregas'

import Downloads from './pages/Downloads'
import DownloadsAdm from './pages/DownloadsAdm'

import MensagensAdm from './pages/MensagensAdm'
import ItensCriticos from './pages/ItensCriticos'

import AgendaPregoes from './pages/AgendaPregoes'
import Consumo from './pages/Consumo'


function Rotas() {
  
  return (
    
    <BrowserRouter>

      <Routes>

          <Route element={<Login />} path='/' />
        
          <Route element={<ProtectedRoute><Layout /></ProtectedRoute>}>
          
            <Route element={<Home />} path='/Inicio' />
            <Route element={<Catalogo />} path='/Catalogo' />
            
            <Route element={<Planejamento />} path='/Planejamento' />
            <Route element={<Processos />} path='/Processos' />
            <Route element={<Empenhos />} path='/Empenhos' />
            <Route element={<Item />} path='/Item' />
            <Route element={<Item />} path='/Item/:slug' />
            <Route element={<Entregas />} path='/Entregas' />
            <Route element={<Entrega />} path='/Entrega' />
            <Route element={<Importacao />} path='/Importacao' />
            <Route element={<Indicadores />} path='/Indicadores' />
            <Route element={<Usuario />} path='/Usuario/:slug' />
            <Route element={<Usuarios />} path='/Usuarios' />
            <Route element={<Usuario />} path='/Usuario' />
            <Route element={<ItensCriticos />} path='/ItensCriticos' />
            <Route element={<Downloads />} path='/Downloads' />            
            <Route element={<DownloadsAdm />} path='/Downloads/Administracao' />
            <Route element={<MensagensAdm />} path='/Mensagens/Administracao' />
            <Route element={<AgendaPregoes />} path='/Agenda/Pregoes' />
            <Route element={<Consumo />} path='/Consumo' />
            
            
          
          </Route>

        
      </Routes>    
    
    
    </BrowserRouter>

  );
}

export default Rotas
