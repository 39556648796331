import { useState } from "react"
import axios from 'axios'
//import { useAtualizaContext } from '../context/atualizaContext'
import { fmtMes } from "../functions/generalFunctions"
import { useNavigate } from "react-router"
import moment from 'moment'
import { useEffect } from "react"

const Modal = () => {

    const navigate = useNavigate()

    
    const linkAPI = process.env.REACT_APP_API
    
    const [file, setFile] = useState()
    const [tipoArquivo, setTipoArquivo] = useState('')

    //const { setDataAtualiza } = useAtualizaContext()


    const anoAtual = moment().year()
    const mesAtual = moment().month()

    const [listaAnos, setListaAnos] = useState()
    const [listaMeses, setListaMeses] = useState()

    const [ano, setAno] = useState(anoAtual.toString())
    const [mes, setMes] = useState(mesAtual.toString())

    async function salvar() {


        if (file == null || file == '') {


            window.toastr.error('Por favor, informe o <b>arquivo</b> para importação')
            return false


        }

        if (tipoArquivo == '') {

            window.toastr.error('Por favor, informe o <b>tipo de arquivo</b> para importação')
            return false

        }


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'green',
		    title: 'Confirmação',
		    content: 'Confirma importar o arquivo?',
		    buttons: {
		        Sim: function () {

                    

                    const formData = new FormData();
                    const imagefile = document.querySelector("#fileMovimento");

                    

                    // 'fileimage' é o campo que o 'multer' procura o arquivo de imagem.
                    formData.append("arquivo", imagefile.files[0]);
                    formData.append("ano", ano);
                    formData.append("mes", mes);
                    formData.append("usuario_id", sessionStorage.getItem('usuario_id'))
                    formData.append("sobrescrever", '1'); // string junto ao formData.
                    
                    //console.log('altera')
                    //return false

                    axios.post(`${linkAPI}/${tipoArquivo}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'x-access-token': '!nf0$@ud#2',
                    }
                    }).then(response => {

                        console.log(response.data)
                        //window.$(".MovimentoImporta").modal('hide');
                        window.$('.modal-Importa').modal('hide')
                        //setImagem(response.data.imagem)
                        //setFile('')
                        //props.carregaAnexos()
                        //setDataAtualiza(Math.random())

                        window.$.confirm({
                            //icon: 'fa fa-question',
                            theme: 'dark',
                            closeIcon: true,
                            animation: 'scale',
                            type: 'orange',
                            title: 'Importaçao de arquivo',
                            content: 'Seu arquivo está em importação, e ísto pode demorar um pouco. Clique em atualizar status para acompanhar a evolução.',
                            buttons: {
                                Ok: function () {

                                    //navigate('/Importacoes')

                                },
                                Cancelar: function () {
                                    //$.alert('Canceled!');
                                }
                
                            },
                
                        });

                        //window.toastr.success('Salvo com sucesso!')



                    }).catch((err) => {

                        console.log(err)
                        console.log(err.response)

                    })


                    
                    

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });


    }


    const carregaAnos = () =>    {

        var x_array = []

        for (var x = 2023; x <= anoAtual; x++) {
            
            x_array.push(x)

        }

        setListaAnos(x_array.sort().reverse().map((ano) =>
        
            <option value={ano}>{ano}</option>
        
        ))
        

    }


    const carregaMeses = () => {

        var x_array = []

        for (var x = 1; x <= 12; x++) {

            x_array.push(x)

        }

        setListaMeses(x_array.map((mes) => 
        
            <option value={mes}>{fmtMes(mes)}</option>
        
        ))


    }


    useEffect(() => {

        carregaAnos()
        carregaMeses()

    }, [])



    return (

        <div>

                <div class="modal fade modal-Importa"
                    id="testeModal"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content"  >
                        <div class="modal-header" >
                                <button style={{ float: 'right'}} type="button" className="modal-close" data-dismiss="modal" aria-label="Close">
                                    x
                                </button>
                            <h4 class="modal-title" id="myModalLabel">Importar dados</h4>
                        </div>
                        <div class="modal-body">

                        <div className="row">

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Tipo de arquivo</label>
                                        <select className="form-control" value={tipoArquivo} onChange={event => setTipoArquivo(event.target.value)} >
                                            <option value="">[Selecione]</option>
                                            <option value="uploadItemConsumo">Tabela Consumo</option>
                                            <option value="uploadItemEstoque">Tabela Estoque</option>
                                            
                                            
                                            <option value="uploadEmpenho">Tabela Empenho</option>
                                            <option value="uploadProcesso">Tabela Processo em Andamento</option>
                                            <option value="uploadItemEntrega">Tabela Entrega</option>
                                            <option value="uploadConsumoMensal">Tabela Consumo Mensal</option>

                                        </select>
                                                        
                                    </div>
                                </div>

                                <div className="col-md-12" >
                                    <fieldset className="form-group">
                                        <label className="form-label" >Ano</label>
                                        <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                                            {listaAnos}
                                        </select>
                                        
                                    </fieldset>
                                </div>

                                <div className="col-md-12" style={{ display: tipoArquivo == 'uploadConsumoMensal' ? 'table-row' : 'none'}}>
                                    <fieldset className="form-group">
                                        <label className="form-label" >Mês</label>
                                        <select type="text" className="form-control" value={mes} onChange={event => setMes(event.target.value)}>
                                            {listaMeses}
                                        </select>
                                        
                                    </fieldset>
                                </div>

                                
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Arquivo</label>
                                        <input type="file" className="form-control" id="fileMovimento" value={file} onChange={event => setFile(event.target.value)} />
                                                        
                                    </div>
                                </div>
                                
                           </div>

                        </div>
                        <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-primary"  data-dismiss="modal">Fechar</button>
                            <button type="button" className="btn btn-inline btn-success" onClick={() => salvar()} disabled={false}>Importar</button>
                            
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )

}

export default Modal