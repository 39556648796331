import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import moment from 'moment'
import { fmtMes } from "../functions/generalFunctions"
import api from "../components/api"
import ModalConsumoMensalDetalhe from '../modal/ModalConsumoMensalDetalhe'

const Consumo = () => {


    const anoAtual = moment().year()
    const [listaAnos, setListaAnos] = useState()
    const [ano, setAno] = useState(anoAtual.toString())

    const [mes, setMes] = useState()
    const [consumoOrigem_id, setConsumoOrigem_id] = useState()

    const [resultadoBusca, setResultadoBusca] = useState('')
    const [itemDescri, setItemDescri] = useState('')
    
    const [codigoMestreMV, setCodigoMestreMV] = useState('')
    const [codigoFilhoMV, setCodigoFilhoMV] = useState('')

    const [siafisicoPregao, setSiafisicoPregao] = useState('')
    const [descricao, setDescricao] = useState('')
    const [unidFornecimento, setUnidFornecimento] = useState('')
    const [item_id, setItem_id] = useState()

    const [colorItem, setColorItem] = useState('white')

    const [listaSetores, setListaSetores] = useState([])
    const [consumoSetor_id, setConsumoSetor_id] = useState()

    const [resultado, setResultado] = useState()
    const [resultadoTotal, setResultadoTotal] = useState([])
    const [resultadoMedia, setResultadoMedia] = useState([])


    const carregaAnos = () =>    {

        var x_array = []

        for (var x = 2024; x <= anoAtual; x++) {
            
            x_array.push(x)

        }

        setListaAnos(x_array.sort().reverse().map((ano) =>
        
            <option value={ano}>{ano}</option>
        
        ))
        

    }

    const carregaSetores = () => {

        api.get('consumoMensalSetores').then((result) => {

            

            setListaSetores(result.data.map((rs) =>
            
                <option value={rs.consumoSetor_id}>{rs.descricao}</option>
            
            ))

        }).catch((err) => {

            console.log(err.response)

        })

    }


    useEffect(() => {

        

        if (itemDescri != '' && item_id == null ) {

            var dataPost = {

                itemDescri: itemDescri,

            }

            api.post('buscaItem', dataPost).then((result) => {

                //console.log(result.data)
                setResultadoBusca(result.data.map((rs) =>
                
                    <Link onClick={() => {setItemDescri(`${rs.descricao}`);setItem_id(rs.item_id);setSiafisicoPregao(rs.siafisicoPregao);setDescricao(rs.descricao);setUnidFornecimento(rs.unidFornecimento);setCodigoMestreMV(rs.codigoMestreMV);setCodigoFilhoMV(rs.codigoFilhoMV)}}><font color='purple'>[{rs.siafisicoPregao}]</font> - {rs.descricao}&nbsp;</Link>
                
                ))

            }).catch((err) => {

                console.log(err.response)

            })


        }


    }, [itemDescri])

    useEffect(() => {

        carregaAnos()
        carregaSetores()

    }, [])


    const consulta = () => {


        var dataPost = {


            item_id: item_id,
            consumoSetor_id: consumoSetor_id,
            ano: ano,


        }

        //console.log(dataPost)


        api.post('consumoMensal', dataPost).then((result) => {


            setResultado(result.data[0].map((rs) => 


                <tr style={{ fontSize: '11px', }}>

                    <td style={{ textAlign: 'center'}}>
                        {fmtMes(rs.mes)}
                    </td>
                    <td style={{ textAlign: 'right'}}>

                        <b>{rs.totalMes}</b>

                    </td>
                    <td style={{ textAlign: 'right'}}>

                        <Link onClick={() => { window.$('#ModalConsumoMensal').modal('show');setMes(rs.mes);setConsumoOrigem_id(1)  }} >{rs.totalDantePazzanese}</Link>

                    </td>
                    <td style={{ textAlign: 'right'}}>

                        <Link onClick={() => { window.$('#ModalConsumoMensal').modal('show');setMes(rs.mes);setConsumoOrigem_id(2)  }} >{rs.totalAdibJatene}</Link>

                    </td>
                    <td style={{ textAlign: 'right'}}>

                        <Link onClick={() => { window.$('#ModalConsumoMensal').modal('show');setMes(rs.mes);setConsumoOrigem_id(4)  }} >{rs.totalSecretariaSaude}</Link>

                    </td>
                    <td style={{ textAlign: 'right'}}>

                        <Link onClick={() => { window.$('#ModalConsumoMensal').modal('show');setMes(rs.mes);setConsumoOrigem_id(3)  }} >{rs.totalInvestimentos}</Link>

                    </td>
                    <th style={{ textAlign: 'right'}}>

                        {item_id ? rs.quantidade : 'N/A'}

                    </th>

                </tr>
                

            ))

            setResultadoTotal(result.data[1].map((rs) => 


                <tr style={{ backgroundColor: '#e4f7f5', fontSize: '11px',  }}>

                    <th style={{ textAlign: 'center'}}>
                        {rs.ano}
                    </th>
                    <th style={{ textAlign: 'right'}}>

                        <b>{rs.totalAno}</b>

                    </th>
                    <th style={{ textAlign: 'right'}}>

                       {rs.totalDantePazzanese}

                    </th>
                    <th style={{ textAlign: 'right'}}>

                        {rs.totalAdibJatene}

                    </th>
                    <th style={{ textAlign: 'right'}}>

                        {rs.totalSecretariaSaude}

                    </th>
                    <th style={{ textAlign: 'right'}}>

                        {rs.totalInvestimentos}

                    </th>
                    <th style={{ textAlign: 'right'}}>

                        {item_id ? rs.quantidade : 'N/A'}

                    </th>

                </tr>
                

            ))

            setResultadoMedia(result.data[2].map((rs) => 


                <tr style={{ backgroundColor: '#f0f2da', fontSize: '11px', color: 'red' }}>

                    <th style={{ textAlign: 'center'}}>
                        Média
                    </th>
                    <th style={{ textAlign: 'right'}}>

                        <b>{rs.totalMedia}</b>

                    </th>
                    <th style={{ textAlign: 'right'}}>

                        {rs.totalDantePazzanese}

                    </th>
                    <th style={{ textAlign: 'right'}}>

                        {rs.totalAdibJatene}

                    </th>
                    <th style={{ textAlign: 'right'}}>

                        {rs.totalSecretariaSaude}

                    </th>
                    <th style={{ textAlign: 'right'}}>

                        {rs.totalInvestimentos}

                    </th>
                    <th style={{ textAlign: 'right'}}>

                        {item_id ? rs.quantidade : 'N/A'}

                    </th>

                </tr>
                

            ))

        }).catch((err) => {

            console.log(err.response)

        })

    }





    useEffect(() => {

        if (item_id) {

            setResultadoBusca('')

            setColorItem('orange')

        } else {

            setColorItem('white')

        }
        

    }, [item_id])

    return (

        <div>

            <div id="contentwrapper">
                <div class="main_content">
                    <div id="jCrumbs" class="breadCrumb module">
                        <ul>
                            <li>
                                <a href="#"><i class="glyphicon glyphicon-home"></i></a>
                            </li>
                            <li>
                            Consumo Mensal
                            </li>
                            
                        </ul>
                    </div>	
                    <div class="row">
                        <div class="col-sm-12 col-md-12">




                            <div className="row">

                                <div className="col-md-12" >
                                    <fieldset className="form-group">
                                        <label className="form-label" >Ano</label>
                                        <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                                            {listaAnos}
                                        </select>
                                        
                                    </fieldset>
                                </div>
                                <div className="col-md-12" >
                                    <fieldset className="form-group">
                                        <label className="form-label" >Setor</label>
                                        <select type="text" className="form-control" value={consumoSetor_id} onChange={event => setConsumoSetor_id(event.target.value)}>
                                            <option value="">[Selecione]</option>
                                            {listaSetores}
                                        </select>
                                        
                                    </fieldset>
                                </div>

                                <div className="col-md-12 col-sm-6">
                                    <fieldset className="form-group">

                                        <textarea  placeholder='Busque pelo item...' className="form-control" value={itemDescri} onChange={event => setItemDescri(event.target.value)} 
                                        style={{ backgroundColor: colorItem }}  onFocus={() => { setItem_id();setItemDescri('');setResultadoBusca('') }}/>
                                        
                                        {resultadoBusca}

                                    </fieldset>
                                </div>
                                <div className="col-md-12 col-sm-6">

                                    <center>
                                        <button type="button" class="btn btn-inline btn-primary" onClick={() => consulta()}>Consultar</button>&nbsp;
                                    </center>
                                    <br/>

                                </div>

                                <div className="col-md-12 col-sm-6" style={{ display: resultado ? 'table-row' : 'none' }}>



                                        <table className="table table-striped table-bordered table-condensed">
                                            <thead>
                                                <tr style={{ textAlign: 'center'}}>
                                                    <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF', textAlign: 'center'}}>
                                                        
                                                        Mês

                                                    </th>
                                                    <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF', textAlign: 'center'}}>
                                                        
                                                        Total

                                                    </th>
                                                    <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF', textAlign: 'center'}}>
                                                        
                                                        Dante Pazzanese

                                                    </th>
                                                    <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF', textAlign: 'center'}}>
                                                        
                                                        Fundação Adib Jatene

                                                    </th>
                                                    <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF', textAlign: 'center'}}>
                                                        
                                                        Secretaria da Saúde

                                                    </th>
                                                    <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF', textAlign: 'center'}}>
                                                        
                                                        Investimentos

                                                    </th>
                                                    <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF', textAlign: 'center'}}>
                                                        
                                                        Quantidade

                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {resultado}
                                            </tbody>
                                            <tfoot>
                                                {resultadoMedia}
                                                {resultadoTotal}                                                
                                            </tfoot>
                                        </table>




                                </div>


                                

                            </div>


                        </div>

                    </div>
                </div>
            </div>


            <ModalConsumoMensalDetalhe mes={mes} consumoOrigem_id={consumoOrigem_id} ano={ano} item_id={item_id} consumoSetor_id={consumoSetor_id} />


        </div>

    )

}

export default Consumo