import { useEffect, useState, useMemo } from 'react'
import api from '../components/api'
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { fmtMes, fmtMoney, sleep } from '../functions/generalFunctions';
import LoaderIcon from "react-loader-icon";

import moment from 'moment'

import ModalEmpenho from '../modal/ModalEmpenho'



const Empenhos = () => {

    const perfil = sessionStorage.getItem('perfil')

    const anoAtual = moment().year()
    const [listaAnos, setListaAnos] = useState()   
    const [ano, setAno] = useState(anoAtual.toString())
    const [mes, setMes] = useState('')
    const [listaMeses, setListaMeses] = useState()

    
    const [resultado, setResultado] = useState([])
    const [empenho_id, setEmpenho_id] = useState()
    const [item_id, setItem_id] = useState()

    const [entrega, setEntrega] = useState('')

    const [carregando, setCarregando] = useState('none')

    const carregaAnos = () => {

        var x_array = []

        for (var x = 2023; x <= anoAtual; x++) {

            x_array.push(x)

        }

        setListaAnos(x_array.sort().reverse().map((ano) =>

            <option value={ano}>{ano}</option>

        ))


    }



    
    async function exportaExcel() {

      var dataPost = {

        ano: ano,
        mes: mes,
        entrega: entrega,

      }


      await api.post(`exportaExcelEmpenho`, dataPost).then((result) => {

        

        if (result.data.status == 'ok') {

          window.toastr.info('Aguarde, gerando XLSX...')

            sleep(2000).then(() => {

                //console.log(result.data[0].fileNameCompleto, '!!')
                //window.location.href = result.data.arquivo
                //window.location.href = `http://192.168.255.165:3378/${result.data.arquivo}`
                window.location.href = `${process.env.REACT_APP_API}/${result.data.arquivo}`
                //window.location.href = `http://172.16.1.253:3378/${result.data.arquivo}`
            })
            

        }

    }).catch((err) => {

        console.log(err.response)

    })


  }




    const carregaMeses = () => {

      var x_array = []

      for (var x = 1; x <= 12; x++) {

          x_array.push(x)

      }

      setListaMeses(x_array.map((mes) => 
      
          <option value={mes}>{fmtMes(mes)}</option>
      
      ))


  }


    const columns = useMemo(
        () => [
          {
            accessorKey: 'siafisicoPregao', //access nested data with dot notation
            header: 'Siafisico',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          {
            accessorKey: 'descricao', //access nested data with dot notation
            header: 'Descrição',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
          },
          {
            accessorKey: 'empenho',
            header: 'Empenho',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          {
            accessorKey: 'dataEmissao', //normal accessorKey
            header: 'Emissão',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          {
            accessorKey: 'dataPrazoEntrega', //normal accessorKey
            header: 'Prazo entrega',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          {
            accessorKey: 'dataEntrega', //normal accessorKey
            header: 'Data entrega',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          {
            accessorKey: 'diasDoPrazoEntrega', //normal accessorKey
            header: 'Dias atraso entrega',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
          },
          
          {
            accessorKey: 'quantidade',
            header: 'Quantidade',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },
          {
            accessorKey: 'valorUnit',
            header: 'Valor Unitário',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },
          {
            accessorKey: 'valorTotal',
            header: 'Valor Total',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },
          
          {
            accessorKey: 'unidFornecimento',
            header: 'Unid. Fornecimento',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          {
            accessorKey: 'numeroProtocolo',
            header: 'Processo',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          {
            accessorKey: 'modalidade',
            header: 'Modalidade',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          {
            accessorKey: 'cnpj',
            header: 'CNPJ',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          {
            accessorKey: 'fornecedor',
            header: 'Fornecedor',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },
          
        ],
        [],
      );


      const carregaEmpenhos = () => {

        var dataPost = {

          ano: ano,
          mes: mes,
          entrega: entrega,
        }

        setCarregando('block')

        //console.log(dataPost, 'aqqq')
    
        api.post('empenhoLista', dataPost).then((result) => {


            const data = result.data
            //console.log(data)
            

            setResultado(data.map((rs) => {


                return {

                    item_id: rs.item_id,
                    empenho_id: rs.empenho_id,
                    siafisicoPregao: rs.siafisicoPregao,
                    empenho: rs.empenho,
                    dataEmissao: rs.dataEmissao,
                    descricao: rs.descricao,
                    quantidade: rs.quantidade,
                    valorUnit: rs.valorUnit,
                    dataPrazoEntrega: rs.dataPrazoEntrega,
                    unidFornecimento: rs.unidFornecimento,
                    numeroProtocolo: rs.numeroProtocolo,
                    dataEntrega: rs.dataEntrega,
                    diasDoPrazoEntrega: rs.diasDoPrazoEntrega > 0 ? rs.diasDoPrazoEntrega : '0',
                    modalidade: rs.modalidade,
                    cnpj: rs.cnpj,
                    fornecedor: rs.fornecedor,
                    valorTotal: rs.valorTotal != null ? fmtMoney(rs.valorTotal, 2) : '0,00',
                    valorUnit: rs.valorUnit != null ? fmtMoney(rs.valorUnit, 2) : '0,00',


                }


            }))

            setCarregando('none')

        }).catch((err) => {

            console.log(err.response)
            setCarregando('none')

        })


    }


    useEffect(() => {

      carregaAnos()
      carregaMeses()

    }, [])


    useEffect(() => {
        
        carregaEmpenhos()
        

    }, [ano, mes, entrega])


    return (

        <div>
 
            <div id="contentwrapper">
                <div class="main_content">
                    <div id="jCrumbs" class="breadCrumb module">
                        <ul>
                            <li>
                                <a href="#"><i class="glyphicon glyphicon-home"></i></a>
                            </li>
                            <li>
                            Processos Empenhados (Fase2)
                            </li>
                            
                        </ul>
                    </div>	
                    <div class="row">
                        <div class="col-sm-12 col-md-12">
        


                        <button type="button" class="btn btn-inline btn-warning" onClick={() => {window.$('.modal-EmpenhoEdit').modal('show');setEmpenho_id()}}  disabled={ perfil == 'Administrador' ? false : true }>+ Novo</button>&nbsp;
                        <button type="button" class="btn btn-inline btn-success" onClick={() => exportaExcel()} >Exportar Excel</button>
                                
                        <br/><br/>


                        <div className="row">
                            <div className="col-md-4 col-sm-6">
                                <fieldset className="form-group">
                                    <label className="form-label" for="exampleError">Ano</label>
                                    <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                                        {listaAnos}
                                    </select>

                                </fieldset>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <fieldset className="form-group">
                                    <label className="form-label" for="exampleError2">Mês</label>
                                    <select type="text" className="form-control" value={mes} onChange={event => setMes(event.target.value)}>
                                        <option value="">[Todos]</option>
                                        {listaMeses}
                                    </select>
                                </fieldset>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <fieldset className="form-group">
                                    <label className="form-label" for="exampleError2">Entrega</label>
                                    <select type="text" className="form-control" value={entrega} onChange={event => setEntrega(event.target.value)}>
                                        <option value="">[Todos]</option>
                                        <option value="N">Somente itens não entregues</option>
                                        
                                        
                                    </select>
                                </fieldset>
                            </div>
                        

                        </div>


                        <LoaderIcon  type={"cylon"} style={{ display: carregando }} />
                        
                        <div style={{ display: carregando == 'none' ? 'block' : 'none'}}>
                          <MaterialReactTable 
                              
                              columns={columns} 
                              data={resultado} 
                              localization={MRT_Localization_PT_BR}
                              //rowsPerPage={20}
                              //options={options}
                              initialState={{ density: 'compact' }}
                              muiTableHeadCellProps={{
                                  //easier way to create media queries, no useMediaQuery hook needed.
                                  sx: {
                                  fontSize: {
                                      xs: '8px',
                                      sm: '9px',
                                      md: '10px',
                                      lg: '11px',
                                      xl: '12px',
                                  },
                                  },
                              }}
                              muiTableBodyCellProps={{
                                  sx: {
                                      fontSize: {
                                      xs: '8px',
                                      sm: '9px',
                                      md: '10px',
                                      lg: '11px',
                                      xl: '12px',
                                      },
                                  },
                              }}

                              muiTableBodyRowProps={({ row }) => ({
                              onClick: (event) => {
                                  //console.info(event, row.original.usuario_id);
                                  //navigate(`/FluxoCaixa/Vinculo/${row.original.vinculo_id}`)
                                  window.$('.modal-EmpenhoEdit').modal('show');setEmpenho_id(row.original.empenho_id);setItem_id(row.original.item_id)

                              },
                              sx: {
                                  cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                              },
                              })}
                              
                          />

                    </div>


                    <ModalEmpenho empenho_id={empenho_id} item_id={item_id} carregaEmpenhos={carregaEmpenhos} />
                    


                        


                        </div>
                    </div>                
                </div>
            </div>


            

        </div>
    )


}

export default Empenhos
