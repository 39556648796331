import { useEffect, useState, useMemo } from "react"
import api from "../components/api"
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import LoaderIcon from "react-loader-icon";
import { fmtMes } from "../functions/generalFunctions"


const Modal = (props) => {

    const mes = props.mes
    const ano = props.ano
    const consumoOrigem_id = props.consumoOrigem_id
    const item_id = props.item_id
    const consumoSetor_id = props.consumoSetor_id

    const [resultado, setResultado] = useState([])
    const [carregando, setCarregando] = useState('none')
    const [consumoOrigem_descri, setConsumoOrigem_descri] = useState('')

    //const [novaSenha, setNovaSenha] = useState('')
    //const [confirmaNovaSenha, setConfirmaNovaSenha] = useState('')



    const columns = useMemo(
        () => [
         
          {
            accessorKey: 'descricao',
            header: 'Descrição',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },

          {
            accessorKey: 'setor',
            header: 'Setor',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },
          {
            accessorKey: 'quantidade',
            header: 'Quantidade',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },
          {
            accessorKey: 'total',
            header: 'Custo',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },
          
          
        ],
        [],
      );


    const carrega = () => {


        var dataPost = {

            mes: mes,
            ano: ano,
            consumoOrigem_id: consumoOrigem_id,
            item_id: item_id,
            consumoSetor_id: consumoSetor_id,

        }
        
        setCarregando('block')

        api.post('consumoMensalDetalhe', dataPost).then((result) => {

            //console.log(result.data)
            setResultado(result.data)
            setConsumoOrigem_descri(result.data[0].consumoOrigem_descri)

            setCarregando('none')
        
        }).catch((err) => {

            console.log(err.response)
            setCarregando('none')

        })

        

    }


    useEffect(() => {

        carrega()

    }, [mes, consumoOrigem_id, ano, item_id, consumoSetor_id])


    return (


        <div>

                <div className={`modal fade modal-ConsumoMensal`}
                    id="ModalConsumoMensal"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content"  >
                        <div class="modal-header" >
                                <button style={{ float: 'right'}} type="button" className="modal-close" data-dismiss="modal" aria-label="Close">
                                    x
                                </button>
                            <h4 class="modal-title" id="myModalLabel">Consumo Mensal - {fmtMes(mes)}/{ano} - {consumoOrigem_descri}</h4>
                        </div>
                        <div class="modal-body">
                        <LoaderIcon  type={"cylon"} style={{ display: carregando }} />
                        <div style={{ display: carregando == 'none' ? 'block' : 'none'}}>
                                <MaterialReactTable 
                                    
                                    columns={columns} 
                                    data={resultado} 
                                    localization={MRT_Localization_PT_BR}
                                    //rowsPerPage={20}
                                    //options={options}
                                    initialState={{ density: 'compact' }}
                                    muiTableHeadCellProps={{
                                        //easier way to create media queries, no useMediaQuery hook needed.
                                        sx: {
                                        fontSize: {
                                            xs: '8px',
                                            sm: '9px',
                                            md: '10px',
                                            lg: '11px',
                                            xl: '12px',
                                        },
                                        },
                                    }}
                                    muiTableBodyCellProps={{
                                        sx: {
                                            fontSize: {
                                            xs: '8px',
                                            sm: '9px',
                                            md: '10px',
                                            lg: '11px',
                                            xl: '12px',
                                            },
                                        },
                                    }}

                                    muiTableBodyRowProps={({ row }) => ({
                                    onClick: (event) => {
                                        //console.info(event, row.original.usuario_id);
                                        //navigate(`/FluxoCaixa/Vinculo/${row.original.vinculo_id}`)
                                        //window.$('.modal-CatalogoEdit').modal('show');setItem_id(row.original.item_id)

                                    },
                                    sx: {
                                        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                                    },
                                    })}
                                    
                                />
                            </div>

                        </div>
                        <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-primary"  data-dismiss="modal">Fechar</button>
                           
                            
                        </div>
                    </div>
                </div>
            </div>


        </div>


    )

}

export default Modal